import $ from 'jquery';

$(() => {
  const $forms = $('form').not('[novalidate]');

  if (!$forms.length) {
    return;
  }

  require.ensure(['jquery-validation'], require => {
    require('jquery-validation');
    require('jquery-validation-unobtrusive');
    require('../extensions/jquery.validate.unobtrusive');
    require('../vendor/mvcfoolproof.unobtrusive');
    require('./core/validation-extensions.js');
  });
});
