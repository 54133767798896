import $ from 'jquery';

$(() => {
  const $elements = $('.js-ot-footer-link');

  if (!$elements.length) {
    return;
  }

  require.ensure(['./core/onetrust-link.js'], require => {
    require('./core/onetrust-link.js');
  });
});
