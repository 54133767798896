/* eslint-disable */

window.adiInit = "28980",
window.adiRVO = true,
window.adiFunc = null;

(function () {
  var adiSrc = document.createElement("script");
  adiSrc.type = "text/javascript";
  adiSrc.async = true;
  adiSrc.src = ("https:" == document.location.protocol ? "https://static-ssl" : "http://static-cdn") + ".responsetap.com/static/scripts/rTapTrack.min.js";

  var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(adiSrc, s);
})();
