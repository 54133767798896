// main template
require('./init-header');
require('./init-site-search');
require('./init-response-tap');
require('./init-usps');
require('./init-live-chat');
require('./init-no-touch');
require('./init-onetrust-link');

// components
require('./init-dom-observers');
require('./init-cookie-panel');
require('./init-digital-assistant');
