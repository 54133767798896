import $ from 'jquery';

$(() => {
  const $jointingCalc = $('.js-jointing-calculator');

  if (!$jointingCalc.length) {
    return;
  }

  require.ensure(['./core/jointingCalc.js'], require => {
    require('./core/jointingCalc');
  });
});
