import $ from 'jquery';

$(() => {
  const $forms = $('.form-component');

  if (!$forms.length) {
    return;
  }

  require.ensure(['./core/forms.js'], require => {
    require('./core/forms.js');
  });

  const $dateInputs = $('.form__control--date');
  if ($dateInputs.length) {
    require.ensure(['./core/forms-date.js'], require => {
      require('./core/forms-date.js');
    });
  }

  const $fileInputs = $('.form__control--file');
  if ($fileInputs.length) {
    require.ensure(['./core/forms-file.js'], require => {
      require('./core/forms-file.js');
    });
  }
});
