import $ from 'jquery';

$(() => {
  const $usps = $('.usp-list');

  if (!$usps.length) {
    return;
  }

  require.ensure(['./core/usps.js'], require => {
    require('@fancyapps/fancybox');
    require('slick-carousel/slick/slick');
    require('./core/usps');
  });
});
