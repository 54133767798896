import 'core-js';
import 'custom-event-polyfill';
import 'loadcss-core';
import 'loadcss-polyfill';
import "whatwg-fetch";
import "../vendor/modernizr-3.6.0";
// import "../service-worker";
import AnalyticsService from '@marshalls/analyticsservice/AnalyticsService';

document.addEventListener("DOMContentLoaded", async () => {
  AnalyticsService.kenticoAnalyticsApiEndpoint = "/api/analytics";
});
