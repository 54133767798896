import $ from 'jquery';

$(() => {
  const $elements = $('.fixed-image');

  if (!$elements.length) {
    return;
  }

  require.ensure(['./core/fixed-image.js'], require => {
    require('./core/fixed-image.js');
  });
});

