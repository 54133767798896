import $ from 'jquery';

$(() => {
  const $container = $('.live-chat__container');

  // check if live chat box is added to body - when it is hide button as it will have it's own controls
  const containerObserver = new MutationObserver(mutationsList => {
    for (let i = 0; i < mutationsList.length; i++) {
      if (mutationsList[i].type === 'childList') {
        if ($('#bc-chat-container').length) {
          $container.hide();
        } else {
          $container.show();
        }
      }
    }
  });

  containerObserver.observe(document.body, { attributes: false, childList: true, subtree: false });

  // on page load check the live chat button directly
  const htmlButton = document.getElementById('js-chat-button');

  const buttonObserver = new MutationObserver((mutationsList, observer) => {
    for (let i = 0; i < mutationsList.length; i++) {
      if (mutationsList[i].type === 'childList') {
        if (htmlButton.querySelector('.online') != null) {
          $container.find('.live-chat__button').addClass('online');
        } else {
          $container.find('.live-chat__button').addClass('offline');
        }

        observer.disconnect();
      }
    }
  });

  buttonObserver.observe(htmlButton, { attributes: false, childList: true, subtree: true });
});
