import $ from 'jquery';

$(() => {

  if ($('.newsletter-form').length) {
    require.ensure(['./core/newsletter-signup.js'], require => {
      require('./core/newsletter-signup.js');
    });

    return;
  }

  if ($('.js-newsletter-signup-form').length) {
    require.ensure(['./core/newsletter-signup-form.js'], require => {
      require('jquery-validation');
      require('jquery-validation-unobtrusive');
      require('./core/newsletter-signup-form.js');
    });
  }
});
