import $ from 'jquery';

$(() => {
  if (!$('.hero-carousel').length) {
    return;
  }

  require.ensure(['./core/carousel-hero.js'], require => {
    require('./core/carousel-hero.js');
  });
});
