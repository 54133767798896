import $ from 'jquery';

$(() => {
  if (!$('.image-hotspot__hotspot-label').length) {
    return;
  }

  require.ensure(['./core/hotspot-label.js'], require => {
    require('./core/hotspot-label');
  });
});
