import $ from 'jquery';

$(() => {
  const $maps = $('.image-comparison-slider');
  if (!$maps.length) {
    return;
  }

  require.ensure(['./core/image-comparison-slider.js'], require => {
    require('./core/image-comparison-slider.js');
  });
});
