import $ from 'jquery';

$(() => {
  if (Modernizr.objectfit) {
    return;
  }

  const $images = $('.object-fit');
  if (!$images.length) {
    return;
  }

  require.ensure(['./polyfill/object-fit.js'], require => {
    require('./polyfill/object-fit.js');
  });
});
