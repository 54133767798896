import $ from 'jquery';

$(() => {
  const $element = $('.js-read-more-button');
  if (!$element.length) {
    return;
  }

  require.ensure(['./core/category-title.js'], require => {
    require('./core/category-title.js');
  });
});
