import $ from 'jquery';

$(() => {
  const $visualiserLaunchBtns = $('.js-visualiser-launch-btn');

  if (!$visualiserLaunchBtns.length) {
    return;
  }

  require.ensure(['./core/visualiser-modal.js'], require => {
    require('./core/visualiser-modal.js');
  });
});
