import $ from 'jquery';

$(() => {
  const $scrollbars = $('.scrollbar-replacement');

  if ($scrollbars.length && !Modernizr.touchevents) {
    require('jquery.scrollbar');
    $scrollbars.scrollbar();
  }
});
