import $ from 'jquery';

$(() => {
  // init address lookup
  const $elements = $('.js-address-lookup');

  if (!$elements.length) {
    return;
  }

  require.ensure(['./core/pca-predict.js'], require => {
    require('./core/pca-predict.js');
  });
});
