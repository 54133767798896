import $ from 'jquery';

$(() => {
  const $carousel = $('.hero-image__carousel');

  if (!$carousel.length) {
    return;
  }

  require.ensure(['./core/hero-image-carousel.js'], require => {
    require('slick-carousel/slick/slick');
    require('./core/hero-image-carousel.js');
  });
});
