import $ from 'jquery';

$(() => {
  // init results
  const $elements = $('.filter-clear-container');

  if (!$elements.length) {
    return;
  }

  require.ensure(['./core/filter.js'], require => {
    require('./core/filter.js');
  });
});
