import $ from 'jquery';

$(() => {
  const $carousel = $('.video-carousel');

  if (!$carousel.length) {
    return;
  }

  require.ensure(['./core/video-carousel.js'], require => {
    require('slick-carousel/slick/slick');
    require('./core/video-carousel.js');
  });
});
