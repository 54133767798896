import $ from 'jquery';

$(() => {
  if (!$(".js-video-hero-title").length) {
    return;
  }
  require.ensure(['./core/video-hero-styling.js'], require => {
    require('./core/video-hero-styling.js');
  });
});
