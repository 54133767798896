import $ from 'jquery';

$(() => {
  if (!$('.faq-list').length) {
    return;
  }

  require.ensure(['./core/faq-list.js'], require => {
    require('./core/faq-list.js');
  });
});
