import $ from 'jquery';
import { EventHelper } from "@marshalls/eventhelper";

const observablePictureClass = 'js-dom-observable--picture';

class DOMImageObserver {
  initialise() {
    setUpObserver($(`.${observablePictureClass}`));
  }

  loadPictures($container) {
    if ($container.length) {
      const $pictures = $container.find(`.${observablePictureClass}`);

      setUpObserver($pictures);
    }
  }
}

function setUpObserver($pictures) {
  if ($pictures.length) {
    if (('IntersectionObserver' in window) && ('IntersectionObserverEntry' in window) && ('intersectionRatio' in window.IntersectionObserverEntry.prototype) && Modernizr.objectfit) {
      loadObserver($pictures.find('img'), observablePictureClass);
    } else {
      loadAllPictures($pictures);
    }
  }
}

function loadObserver($images, parentClass) {
  const threshold = !!navigator.userAgent.match(/firefox/i) ? 0 : 0.01; // eslint-disable-line no-extra-boolean-cast
  const options = {
    root: null,
    rootMargin: "0px",
    threshold,
  };
  const observer = new IntersectionObserver(observerCallback, options);
  const dataAttribute = "is-observed";

  $images.each((index, element) => {
    const $element = $(element);

    if (!$element.data(dataAttribute) && $element.parent().hasClass(parentClass)) {
      observer.observe(element);
      $element.data(dataAttribute, true);
    }
  });
}

function observerCallback(entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const target = entry.target;
      loadPicture($(target).parent());

      observer.unobserve(target);
    }
  });

  EventHelper.dispatchEvent("domObserverHandlePictureIntersectionEnd", document);
}

function loadAllPictures($pictures) {
  $pictures.each((index, element) => {
    const $picture = $(element);
    loadPicture($picture);
  });
};

function loadPicture($picture) {
  $picture.children().each((index, item) => {
    const $item = $(item);

    if ($item.is('source')) {
      $item.attr('srcset', $item.data('src'));
    } else if ($item.is('img')) {
      $item.attr('src', $item.data('src'));
      $item.one('load', () => {
        $picture.removeClass(observablePictureClass);
      });
    }
  });
};

const instance = new DOMImageObserver();

export default instance;
