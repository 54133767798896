import $ from 'jquery';

const observableBackgroundClass = 'js-dom-observable--background';

class DOMBackgroundObserver {
  initialise() {
    setUpObserver($(`.${observableBackgroundClass}`));
  }

  loadBackgrounds($container) {
    if ($container.length) {
      const $elements = $container.find(`.${observableBackgroundClass}`);

      setUpObserver($elements);
    }
  }
}

function setUpObserver($elements) {
  if ($elements.length) {
    if (('IntersectionObserver' in window) && ('IntersectionObserverEntry' in window) && ('intersectionRatio' in window.IntersectionObserverEntry.prototype) && Modernizr.objectfit) {
      loadObserver($elements);
    } else {
      loadAllBackgrounds($elements);
    }
  }
}

function loadObserver($elements) {
  const threshold = !!navigator.userAgent.match(/firefox/i) ? 0 : 0.01; // eslint-disable-line no-extra-boolean-cast
  const options = {
    root: null,
    rootMargin: "0px",
    threshold,
  };

  const observer = new IntersectionObserver(observerCallback, options);
  const dataAttribute = "is-observed";

  $elements.each((index, element) => {
    const $element = $(element);

    if (!$element.data(dataAttribute)) {
      observer.observe(element);
      $element.data(dataAttribute, true);
    }
  });
}

function observerCallback(entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const target = entry.target;

      $(target).removeClass(observableBackgroundClass);
      observer.unobserve(target);
    }
  });
}

function loadAllBackgrounds($elements) {
  $elements.removeClass(observableBackgroundClass);
};

const instance = new DOMBackgroundObserver();

export default instance;
