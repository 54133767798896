import $ from 'jquery';

$(() => {
  // init results
  const $title = $('#buy-online__search-results-title');

  if (!$title.length) {
    return;
  }

  require.ensure(['./core/buy-online-results.js'], require => {
    require('./core/buy-online-results.js');
  });
});
