import $ from 'jquery';

$(() => {
  const $forms = $('.multi-form');

  if (!$forms.length) {
    return;
  }

  require.ensure(['./core/multi-form.js'], require => {
    require('./core/multi-form.js');
  });
});
