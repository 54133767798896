import $ from 'jquery';
import elementHelper from '@marshalls/nativejselementhelper/NativeJsElementHelper';
import mobileChecker from '@marshalls/mobilechecker/MobileChecker';
import imageObserver from './class/DOMImageObserver';

$(() => {
  const openClass = 'open';
  const hoverClass = 'hover';
  const siteOveraly = 'site-overlay';
  const noclick = 'no-click';
  const clickable = 'clickable';
  const showDropDown = 'show-site-drop-down';
  const showHomeBtn = 'show-home-btn';
  const rotateDropDownIcon = "rotate-drop-down-icon";
  const sectionOpenClass = 'open-section';
  const bodyClass = 'no-scroll';
  const closeClass = 'site-header__section-close';
  const $body = $('body');
  const $header = $('.site-header');
  const $headerSite = $('.site-header__site');
  const $underlinedList = $header.find('.site-header__link-list--underlined');
  const $back = $(elementHelper.createElement('span', closeClass));
  const cloneArray = [];
  const $dropDownContainerMobile = $('.site-header__mobile-list-item');
  const $dropDownButton = $('.js-site-drop-down');
  const $dropDown = $('.site-header__site-drop-down__content__container');
  const $dropDownIcon = $('.site-header__site-drop-down__holder__icon');
  const $homeButton = $('.site-header__home');
  const $burger = $header.find('.site-header__burger-holder');
  let resizeTimout;
  let hoverTimout;
  let $clone;

  const $overlay = $("<span></span>")
  $body.append($overlay);

  window.onload = () => {
    $('.material-icons').css('opacity', '1');
  };

  $dropDownButton.on('click', () => {
    $dropDown.toggleClass(showDropDown);
    $dropDownIcon.toggleClass(rotateDropDownIcon);
    $homeButton.toggleClass(showHomeBtn);
    $overlay.toggleClass(siteOveraly);
    if ($header.hasClass(openClass)) {
      $burger.click();
    }
    $body.toggleClass(noclick);
    $dropDownContainerMobile.toggleClass(clickable);
    $headerSite.toggleClass(clickable);
  });

  $underlinedList
    .children('.site-header__link-list-item')
    .on('mouseenter', e => {
      const $current = $(e.currentTarget);

      clearTimeout(hoverTimout);
      hoverTimout = setTimeout(() => {
        $current.addClass(hoverClass);
      }, 150);

    })
    .on('mouseleave', e => {
      clearTimeout(hoverTimout);
      $(e.currentTarget).removeClass(hoverClass);
    });

  $underlinedList
    .find('.site-header__link')
    .on('click', e => {
      const $link = $(e.currentTarget);

      if ($link.closest(`.${openClass}`).length) {
        const $sectionHolder = $link.next('.site-header__section-holder');
        const cloneKey = $link.data('key');

        if ($sectionHolder.length) {
          e.preventDefault();

          if (cloneKey in cloneArray) {
            $clone = cloneArray[cloneKey];
          } else {
            $clone = $sectionHolder.clone();
            $clone.addClass('clone').children('.site-header__section').each((index, section) => {
              const $section = $(section);
              $section.css('order', $section.data('mobile-order'));
            });

            imageObserver.loadPictures($clone);
            cloneArray[cloneKey] = $clone;
          }

          $header.addClass(sectionOpenClass).append($clone.prepend($back));
          imageObserver.loadPictures($header);
        }
      }
    });

  $header.on('click', `.${closeClass}`, () => {
    $header.removeClass(sectionOpenClass);
    $clone.remove();
  });

  

  $burger.on('click', () => {
    $header.toggleClass(openClass);
    $body.toggleClass(bodyClass);

    if ($header.hasClass(sectionOpenClass)) {
      $header.removeClass(sectionOpenClass);
      $clone.remove();
    }
  });

  $(window).on('resize', () => {
    clearTimeout(resizeTimout);

    resizeTimout = setTimeout(closeMobile, 125);
  });

  function closeMobile() {
    if (mobileChecker.isDesktop() && $header.hasClass(openClass)) {
      $header.removeClass(openClass);
      $body.removeClass(bodyClass);

      if ($header.hasClass(sectionOpenClass)) {
        $header.removeClass(sectionOpenClass);
        $clone.remove();
      }
    }
  }
});
