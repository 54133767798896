import $ from 'jquery';

$(() => {
  // initiate video fancybox
  if ($('.fancybox-video').length) {
    require.ensure(['./core/fancybox-video.js'], require => {
      require('@fancyapps/fancybox');
      require('./core/fancybox-video.js');
    });
  }

  // initiate related-content fancybox
  if ($('.fancybox-related-content').length) {
    require.ensure(['./core/fancybox-related-content.js'], require => {
      require('@fancyapps/fancybox');
      require('./core/fancybox-related-content.js');
    });
  }

  if ($('.fancybox-modal').length) {
    require.ensure(['./core/fancybox-modal.js'], require => {
      require('@fancyapps/fancybox');
      require('./core/fancybox-modal.js');
    });
  }
});

