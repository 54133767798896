require('./base');

// components
require('@marshalls/blog/init-blog-view');

require('./init-address-lookup');
require('./init-buy-online');
require('./init-buy-online-results');
require('./init-carousel-card');
require('./init-carousel-hero');
require('./init-category-title');
require('./init-delivery-progress-map');
require('./init-faq-list');
require('./init-fancybox');
require('./init-fixed-image');
require('./init-form-validation');
require('./init-forms');
require('./init-filter');
require('./init-google-map');
require('./init-hero-image-carousel');
require('./init-hotspot-label');
require('./init-image-comparison-slider');
require('./init-masonry-gallery');
require('./init-multi-form');
require('./init-newsletter-signup-form');
require('./init-register-recruitment.js');
require('./init-section-navigation');
require('./init-start-project');
require('./init-video-carousel');
require('./init-jointing-calculator');
require('./init-video-hero-styling');
require('./init-visualiser');

// run at end
require('./base-end');

