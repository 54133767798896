import $ from 'jquery';
import 'corejs-typeahead/dist/typeahead.jquery';
import Bloodhound from 'corejs-typeahead/dist/bloodhound';
import Cookies from 'js-cookie';
import elementHelper from '@marshalls/nativejselementhelper/NativeJsElementHelper';

$(() => {
  const minSearchChars = 2;
  const $searchForm = $('.js-site-header__search-form');
  const $searchInput = $searchForm.find('.js-site-header__search-input');
  const searchResultUrl = $searchForm.get(0).action;
  const typeaheadUrl = $searchInput.data('typeahead-url')

  // search drop down
  const $searchDropdown = $(elementHelper.createElement('div', 'site-header__search-dropdown'));

  addSearchDropdown();

  const $suggestionsHolder = $searchDropdown.find('.site-header__search-suggestions');
  const $loading = $suggestionsHolder.find('.site-header__search-loading');
  const $suggestionsAll = $searchDropdown.find('.site-header__search-suggestions-all');
  const $close = $searchDropdown.find('.site-header__search-close');

  // previous search
  const previousMaxResults = 5;
  const previousCookieName = 'domestic-previousSearches';
  const $previousHolder = $searchDropdown.find('.site-header__search-previous');
  const $previousParent = $previousHolder.parent();
  const $previousClear = $searchDropdown.find('.site-header__search-previous-clear');
  let previousLoaded = false;

  // site container
  const siteContainerClass = 'site-container__lower--overlay';
  const $siteContainer = $('.site-container__lower');

  // BLOODHOUND
  const searchResults = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('Title'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: typeaheadUrl,
      prepare(query, settings) {
        const mySettings = settings;
        mySettings.Type = "GET";
        mySettings.contentType = "application/json; charset=UTF-8";
        mySettings.url += query;
        return mySettings;
      }
    }
  });

  // Fire off TYPEAHEAD
  $searchInput.typeahead({
    hint: false,
    highlight: true,
    minLength: minSearchChars
  },
    {
      name: 'searchResults',
      displayKey: 'Title',
      source: searchResults,
      limit: 10,
      templates: {
        suggestion() {
          // This 'pile o shite' plugin reqs you to return an element, so av this you pile of pants
          return "<span style='display:none;'></span>";
        }
      }
    });

  $searchInput.on('keyup click focus', event => {
    if (event.currentTarget.value.trim().length >= minSearchChars) {
      showPreviousSearches();
      $searchDropdown.show();
      $siteContainer.addClass(siteContainerClass);
    } else {
      $searchDropdown.hide();
      $siteContainer.removeClass(siteContainerClass);
    }
  }).on('typeahead:render', (event, suggestions, isAsync) => {
    const theSuggestions = suggestions;
    $suggestionsHolder.empty().append($loading);

    // Remove anything that isn't a product
    for (let i = theSuggestions.length; i > 0; i -= 1) {
      const curSuggestion = theSuggestions[i - 1];
      if (curSuggestion.Type && curSuggestion.Type.toLowerCase() !== "product") {
        theSuggestions.splice(i - 1, 1);
      }
    }

    if (isAsync) {
      $loading.remove();

      if (theSuggestions.length > 0) {
        $suggestionsAll.show();

        for (let i = 0; i < theSuggestions.length; i += 1) {
          const currentSuggestion = theSuggestions[i];

          $suggestionsHolder
            .append(`<a class="site-header__search-suggestions-link" href="${currentSuggestion.Url}">${currentSuggestion.Title}</a>`);
        }
      } else {
        $suggestionsHolder.html('<p class="site-header__search-no-results"><strong>We can\'t find any results</strong></p>');
        $suggestionsAll.hide();
      }
    }
  });

  $close.on('click', () => {
    $searchDropdown.hide();
    $siteContainer.removeClass(siteContainerClass);
  });

  $suggestionsAll.on('click', e => {
    e.preventDefault();
    $searchForm.submit();
  });

  $previousClear.on('click', () => {
    Cookies.remove(previousCookieName);
    $previousHolder.empty();
    $previousParent.hide();
  });

  $(window).on("pagehide", () => {
    updateSearchesCookie();
  });

  function showPreviousSearches() {
    if (!previousLoaded) {
      previousLoaded = true;
      const checkForPreviousSearches = Cookies.get(previousCookieName);

      if (checkForPreviousSearches === undefined) {
        $previousHolder.empty();
        $previousParent.hide();
      } else {
        const cookieJSON = Cookies.getJSON(previousCookieName);

        $previousHolder.empty();
        $previousParent.show();

        for (let i = 0; i < cookieJSON.length; i += 1) {
          $previousHolder.append(`<a class="site-header__search-previous-link" href="${searchResultUrl}?search=${encodeURIComponent(cookieJSON[i])}">${cookieJSON[i]}</a>`);
        }
      }
    }
  }

  const searchId = "side-search";
  const mobileSearchId = "side-search-mobile";
  const hoverText = "Search";
  const defaultText = "I'm looking for products, colours, materials, textures or inspiration";
  const mobileDefaultText = "Search...";

  if (document.body.contains(document.getElementById(searchId))) {
    const searchbox = document.getElementById(searchId);
    searchbox.placeholder = defaultText;
    searchbox.addEventListener('mouseover', () => {
      searchbox.placeholder = hoverText;
    });
    searchbox.addEventListener('mouseout', () => {
      searchbox.placeholder = defaultText;
    });
  }

  if (document.body.contains(document.getElementById(mobileSearchId))) {
    const searchbox = document.getElementById(mobileSearchId);
    searchbox.placeholder = mobileDefaultText;
    searchbox.addEventListener('mouseover', () => {
      searchbox.placeholder = hoverText;
    });
    searchbox.addEventListener('mouseout', () => {
      searchbox.placeholder = mobileDefaultText;
    });
  }

  function updateSearchesCookie() {
    const searchVal = $searchInput.val();

    if (searchVal.length > minSearchChars) {
      const searchText = $(elementHelper.createElement('div')).append(searchVal).text().toLowerCase().trim(); // strip out any html

      if (searchText.length > minSearchChars) {
        const checkForPreviousSearches = Cookies.get(previousCookieName);
        if (checkForPreviousSearches === undefined) {
          const searchArray = [];
          searchArray.push(searchText);

          Cookies.set(previousCookieName, searchArray, { expires: 9999, sameSite: 'strict' });
        } else {
          const cookieJSON = Cookies.getJSON(previousCookieName);

          if (cookieJSON.includes(searchText)) {
            const index = cookieJSON.indexOf(searchText);

            if (index > -1) {
              cookieJSON.splice(index, 1);
            }
          }

          if (cookieJSON.length === previousMaxResults) {
            cookieJSON.splice(previousMaxResults - 1, 1);
          }

          cookieJSON.unshift(searchText);
          Cookies.set(previousCookieName, cookieJSON, { expires: 9999, sameSite: 'strict' });
        }
      }
    }
  }

  function addSearchDropdown() {
    $searchDropdown.html(`
      <span class="site-header__search-close"></span>
      <div class="site-header__search-dropdown-inner">
        <div class="site-header__search-suggestions-holder">
          <p class="site-header__search-dropdown-heading">Suggestions</p>
          <div class="site-header__search-suggestions">
            <span class="site-header__search-loading"></span>
          </div>
          <button class="site-header__search-suggestions-all">View all results</button>
        </div>
        <div class="site-header__search-previous-holder">
          <p class="site-header__search-dropdown-heading">Previous Searches</p>
          <div class="site-header__search-previous">
          </div>
          <span class="site-header__search-previous-clear">Clear all</span>
        </div>
      </div>`);

    $searchForm.after($searchDropdown);
  }
});
