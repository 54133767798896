import $ from 'jquery';

$(() => {
  const $gallery = $('.masonry-gallery').not('.masonry-gallery--inspiration');

  if (!$gallery.length) {
    return;
  }

  require.ensure(['./core/masonry-gallery.js'], require => {
    require('@fancyapps/fancybox');
    require('./core/masonry-gallery');
  });
});
