import $ from 'jquery';

$(() => {
  // init typeahead
  const $startProjectContainer = $('.start-project-cta');

  if (!$startProjectContainer.length) {
    return;
  }

  require.ensure(['./core/start-project.js'], require => {
    require('./core/start-project.js');
  });
});
