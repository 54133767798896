import $ from 'jquery';

$(() => {
  const $elements = $('.no-touch');
  if (!$elements.length) {
    return;
  }

  if ('ontouchstart' in document) {
    $elements.removeClass('no-touch');
  }
});
