import $ from 'jquery';

$(() => {
  const $sectionNavigation = $('.prepend-section-navigation,.js-section-navigation');

  if (!$sectionNavigation.length) {
    return;
  }

  require.ensure(['./core/section-navigation.js'], require => {
    require('./core/section-navigation.js');
  });
});
