import $ from 'jquery';

$(() => {
  // init address lookup
  const $elements = $('.delivery-progress__map');

  if (!$elements.length) {
    return;
  }

  require.ensure(['./core/delivery-progress-map.js'], require => {
    require('./core/delivery-progress-map.js');
  });
});
