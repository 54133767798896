import $ from 'jquery';

$(() => {
  const $productForm = $('.buy-online__form--product');

  if (!$productForm.length) {
    return;
  }

  require.ensure(['./core/buy-online-product-form.js'], require => {
    require('./core/buy-online-product-form.js');
  });
});
