import $ from 'jquery';

$(() => {
  if (!$('.card-carousel').length) {
    return;
  }

  require.ensure(['./core/carousel-card.js'], require => {
    require('slick-carousel/slick/slick');
    require('./core/carousel-card.js');
  });
});
